import * as React from 'react';
import { FC } from 'react';
import { Credential, Did, DidService } from '../../state';
import { OCard, TextWithLabel } from '../molecules';
import { CredentialStatusBadge } from '../molecules/CredentialStatusBadge';

interface ShowServicesViewProps {
    did?: Did;
    className?: string | undefined;
    rightHeaderElement?: React.ReactNode;
}


export const ShowDidView: FC<ShowServicesViewProps> = (props) => {

    function showCredentials(credentials: Credential[]) {
        return credentials.map((credential, index) =>
            <OCard className="mb-4"
                   title={credential.displayName}
                   titlePrefix={<i className={"mr-2 pi " + credential.icon}/>}
                   titlePostfix={<CredentialStatusBadge status={credential.status} validationPolicyResult={credential.validationPolicyResults}/>}
                   key={index}>

                {credential.attributes.map((attribute, index2) => (
                    <TextWithLabel label={attribute.displayName} value={attribute.value} key={index2}/>
                ))}
            </OCard>
        )
    }

    function showService(service: DidService) {
        if ((service.credentials === undefined) || (service.credentials.length === 0)) {
            return <OCard className="mb-4" title={service.title} titlePrefix={<i className={"mr-2 pi " + service.icon}/>}>
                <TextWithLabel label="Service Id" value={service.serviceId}/>
                {(service.serviceType !== undefined) && (<TextWithLabel label={service.serviceTypeLabel} value={service.serviceType}/>)}
                {(service.serviceTypeJson !== undefined) && (<TextWithLabel label={service.serviceTypeLabel} value={service.serviceTypeJson}/>)}
                {(service.serviceEndpoint !== undefined) && (<TextWithLabel label={service.serviceEndpointLabel} value={service.serviceEndpoint}/>)}
                {(service.serviceEndpointJson !== undefined) && (<TextWithLabel label={service.serviceEndpointLabel} value={service.serviceEndpointJson}/>)}
            </OCard>
        } else {
            return showCredentials(service.credentials);
        }
    }

    return (
        <div className={props.className}>
            <div className="flex justify-content-between align-items-start">
                <div>
                    <div className="text-3xl">{props.did?.title?.value}</div>
                    <div className="text-xs">{props.did?.subTitle1?.value}</div>
                    <div className="text-xs mb-4">{props.did?.subTitle2?.value}</div>
                </div>
                {props.rightHeaderElement}
            </div>
            {props.did?.services?.map((service, index) => (
                <div key={index}>
                    {showService(service)}
                </div>
            ))}
        </div>
    );
}
