import React from 'react';
import { IconProps } from './IconProps';


export const AddCheckmark: React.FC<IconProps> = ({width = '20', height = "20", className}: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 115 115" fill="none" className={className}>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M75.5121 36.815C78.0927 34.2344 82.2823 34.2344 84.8639 36.815C87.4455 39.3987 87.4455 43.5851 84.8639 46.1688L52.8486 78.1862C50.2671 80.7624 46.0774 80.7624 43.4948 78.1862L30.1362 64.8276C27.5547 62.2439 27.5547 58.0522 30.1362 55.4727C32.7178 52.8911 36.9075 52.8911 39.488 55.4727L48.1712 64.1559L75.5111 36.816L75.5121 36.815ZM57.4145 3.81554C61.9007 3.85062 64.8659 -0.637661 69.148 0.191337C73.1559 0.970382 74.2506 6.32804 77.9641 7.86806C82.0952 9.61959 86.5516 6.6065 90.1907 9.01272C93.5981 11.2659 92.5587 16.6342 95.3996 19.4772C98.5455 22.6752 103.818 21.5986 106.258 25.2122C108.543 28.5972 105.526 33.1578 107.065 36.8734C108.747 41.0354 114.031 42.0557 114.901 46.3293C115.717 50.3297 111.185 53.3917 111.185 57.4102C111.147 61.8985 115.638 64.8627 114.807 69.1459C114.029 73.158 108.67 74.2506 107.132 77.964C105.381 82.092 108.395 86.5559 105.987 90.1928C103.732 93.596 98.3648 92.5565 95.525 95.3996C92.3227 98.5455 93.4004 103.816 89.7858 106.258C86.3986 108.546 81.8402 105.528 78.1246 107.065C73.9647 108.745 72.9423 114.029 68.6687 114.901C64.6661 115.715 61.6063 111.188 57.5867 111.18C53.1005 111.148 50.1321 115.639 45.8511 114.804C41.8432 114.03 40.7506 108.668 37.035 107.131C32.9049 105.379 28.4464 108.391 24.8094 105.986C21.3999 103.733 22.4425 98.3648 19.6005 95.5218C16.4546 92.3259 11.182 93.3972 8.74173 89.7868C6.45668 86.4017 9.47402 81.8412 7.93506 78.1277C6.25262 73.9604 0.969353 72.939 0.0989055 68.6697C-0.717338 64.665 3.81664 61.603 3.81664 57.5877C3.85171 53.1016 -0.636564 50.1331 0.192433 45.85C0.971478 41.841 6.32914 40.7495 7.86916 37.036C9.62068 32.9049 6.60653 28.4442 9.01381 24.8072C11.267 21.3999 16.6363 22.4403 19.4762 19.6016C22.6763 16.4556 21.5986 11.1819 25.2154 8.73957C28.6005 6.45664 33.161 9.47186 36.8766 7.93502C41.0365 6.25258 42.0579 0.967193 46.3325 0.0988715C50.333 -0.718435 53.3949 3.81235 57.4156 3.81661L57.4145 3.81554Z"
                  fill="#006EF3"/>
        </svg>

    )
};
