import * as React from 'react';
import { FC } from 'react';

export const Home: FC = () => {

    console.log('QQQ home');
    return (
        <div>Home</div>
    );
}
